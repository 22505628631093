import React from 'react';
import px from 'prop-types';
import { Translation } from 'Common/components/localization';

const alertTypeToClass = {
    success: 'alert alert-success',
    failure: 'alert alert-danger',
};

export default function CatalogCompareToast({ type, messageKey, messageParams, comparePageUrl }) {
    return (
        <div className={alertTypeToClass[type]}>
            <Translation id={messageKey} params={messageParams} />

            {type === 'success' ? (
                <>
                    {' '}
                    <a href={comparePageUrl}>
                        <Translation id="Commerce.Product.Compare.Alert.ViewFullList" />
                    </a>
                </>
            ) : null}
        </div>
    );
}

CatalogCompareToast.propTypes = {
    type: px.oneOf(Object.keys(alertTypeToClass)).isRequired,
    messageKey: px.string.isRequired,
    messageParams: px.object.isRequired,
    comparePageUrl: px.string.isRequired,
};
