import React from 'react';
import px from 'prop-types';
import { CatalogCompareToast as CompareToast } from 'Common/components/catalog';
import { compare } from '~features';
import { useSelector } from 'react-redux';

const timeoutDurationMs = 5000;

export default function CatalogCompareToast({ comparePageUrl }) {
    const alert = useSelector(compare.selectors.getAlert);

    const timeout = React.useRef();
    const [visible, setVisible] = React.useState(false);

    React.useEffect(() => {
        if (alert.timestamp) {
            setVisible(true);

            if (timeout.current) {
                clearTimeout(timeout.current);
            }

            setTimeout(() => setVisible(false), timeoutDurationMs);
        }
    }, [alert]);

    return visible ? (
        <CompareToast
            type={alert.message.type}
            messageKey={alert.message.translationKey}
            messageParams={alert.message.params}
            comparePageUrl={comparePageUrl}
        />
    ) : null;
}

CatalogCompareToast.propTypes = {
    comparePageUrl: px.string.isRequired,
};
