import React from 'react';
import px from 'prop-types';
import { compare } from '~features';
import { useAction } from 'Common/hooks';
import { useSelector } from 'react-redux';
import { CatalogCompare as Compare } from 'Common/components/catalog';

export default function CatalogCompare({ comparePageUrl }) {
    const compareList = useSelector(compare.selectors.getCompareItems);
    const isLoading = useSelector(compare.selectors.getLoading);

    const onGetCompareList = useAction(compare.actions.getCompareList);
    const onRemoveFromCompareList = useAction(compare.actions.removeFromCompareList);
    const onClearCompareList = useAction(compare.actions.clearCompareList);

    React.useEffect(() => {
        onGetCompareList();
    }, [onGetCompareList]);

    return (
        <Compare
            items={compareList}
            onRemoveFromCompareList={onRemoveFromCompareList}
            onClearCompareList={onClearCompareList}
            comparePageUrl={comparePageUrl}
            isLoading={isLoading}
        />
    );
}

CatalogCompare.propTypes = {
    comparePageUrl: px.string.isRequired,
};
