import * as React from 'react';
import { useSelector } from 'react-redux';
import { useAction } from 'Common/hooks';
import * as GTM from 'Common/constants/gtm';
import { product, order } from 'Common/features';
import { ProductAttributes as Attributes } from 'Common/components/product/ProductTabContent';

export default function ProductAttributes() {
    const productChildren = useSelector(product.selectors.getChildren);
    const activeChild = useSelector(product.selectors.getSingleActiveChild);
    const onAddItemToCart = useAction(order.actions.addItem);
    const orderLastUpdate = useSelector(order.selectors.getLastUpdate);
    const totalQty = useSelector(order.selectors.getTotalQuantity);
    const productInfo = useSelector(product.selectors.getProduct);

    const attributes = React.useMemo(
        () =>
            productChildren
                ?.reduce(
                    (acc, variant) => [
                        ...acc,
                        ...variant.Attributes.filter(
                            (newAttribute) =>
                                newAttribute.IsSpecification &&
                                !acc.find(
                                    (existingAttribute) =>
                                        existingAttribute.AttributeName === newAttribute.AttributeName
                                )
                        ),
                    ],
                    []
                )
                .sort((attr1, attr2) => attr1.SortOrder - attr2.SortOrder),
        [productChildren]
    );

    return (
        <Attributes
            activeChild={activeChild}
            attributes={attributes}
            onAddItemToCart={onAddItemToCart}
            productChildren={productChildren}
            orderLastUpdate={orderLastUpdate}
            gtmListValue={GTM.TAGS.PDP}
            statusTooltip={'top'}
            productInfo={productInfo}
            totalQty={totalQty}
        />
    );
}
