import React from 'react';
import px from 'prop-types';
import cx from 'classnames';
import { Translation } from 'Common/components/localization';
import { useTranslation } from 'Common/hooks';
import { Spinner } from 'Common/components/ui';

export default function CatalogCompare({
    items,
    onRemoveFromCompareList,
    onClearCompareList,
    comparePageUrl,
    isLoading,
    className,
}) {
    const onClickRemove = React.useCallback((code) => () => onRemoveFromCompareList(code), [onRemoveFromCompareList]);
    const onClickClear = React.useCallback(() => onClearCompareList(), [onClearCompareList]);
    const lblRemove = useTranslation('Commerce.Order.Cart.RemoveItem.Label');

    return (
        <div className={cx('CatalogCompare', className)}>
            <h3 className="CatalogCompare__title">
                <Translation id="Commerce.Product.Compare.SectionHeader" /> (
                <a href={comparePageUrl}>
                    <Translation id="Commerce.Product.Compare.ViewAll" />
                </a>
                )
            </h3>
            {isLoading ? (
                <Spinner />
            ) : (
                <div>
                    {items?.length ? (
                        <>
                            <ul className="CatalogCompare__list">
                                {items.map((item) => (
                                    <li key={item.Code}>
                                        <button
                                            onClick={onClickRemove(item.Code)}
                                            className="CatalogCompare__delete-button"
                                            aria-label={lblRemove}
                                        >
                                            <i className="fa fa-times"></i>
                                        </button>{' '}
                                        {item.Title}
                                    </li>
                                ))}
                            </ul>
                            <div>
                                <button className="btn btn-danger" onClick={onClickClear}>
                                    <Translation id="Commerce.Product.Compare.Clear" />
                                </button>
                            </div>
                        </>
                    ) : (
                        <Translation id="Commerce.Product.Compare.NoItems" />
                    )}
                </div>
            )}
        </div>
    );
}

CatalogCompare.propTypes = {
    items: px.arrayOf(
        px.shape({
            Code: px.string.isRequired,
            Title: px.string.isRequired,
        })
    ).isRequired,
    onRemoveFromCompareList: px.func.isRequired,
    onClearCompareList: px.func.isRequired,
    comparePageUrl: px.string.isRequired,
    isLoading: px.bool,
    className: px.string,
};
