import React from 'react';
import cx from 'classnames';
import px from 'prop-types';
import { Formatter } from 'Common/utils';
import { Spinner } from 'Common/components/ui';
import { StockStatus } from 'Common/constants/product';
import { Translation } from 'Common/components/localization';
import $ from 'jquery';

function priceProps(listPrice, salePrice) {
    const retailStr = listPrice ? Formatter.currency(listPrice) : '';
    const saleStr = salePrice && salePrice < listPrice ? Formatter.currency(salePrice) : '';

    return { price: retailStr, reducedPrice: saleStr };
}

export default function ProductCompare({
    items,
    specs,
    onRemoveFromCompareList,
    onClearCompareList,
    onAddItemToCart,
    isLoading,
}) {
    const onClickRemove = React.useCallback((code) => () => onRemoveFromCompareList(code), [onRemoveFromCompareList]);
    const onClickAddToCart = React.useCallback((code) => () => onAddItemToCart(code), [onAddItemToCart]);

    const onClickClear = React.useCallback(() => {
        onClearCompareList();
        $('html,body').animate({ scrollTop: 0 });
    }, [onClearCompareList]);

    return isLoading ? (
        <Spinner />
    ) : items?.length ? (
        <div className="ProductCompare w-100 overflow-auto">
            <table className="ProductCompare__table table border-0 w-auto mb-2">
                <thead>
                    <tr>
                        <th></th>
                        {items.map((item) => {
                            const { price, reducedPrice } = priceProps(item.ListPrice, item.SalePrice);

                            return (
                                <th key={item.Code} scope="col" className="position-relative h-100">
                                    <div className="h-100 d-flex flex-column">
                                        <a
                                            className="ProductCompare__table__product-link d-flex flex-column w-100 flex-grow-1"
                                            href={item.Url}
                                        >
                                            <div className="flex-grow-1 mb-2">
                                                {item.ImageUrl ? (
                                                    <img className="img-fluid" src={item.ImageUrl} alt={item.Title} />
                                                ) : null}
                                            </div>

                                            <div className="mb-2">{item.Title}</div>

                                            <div className="CatalogCard__prices">
                                                <div className={cx('CatalogCard__price', { strike: reducedPrice })}>
                                                    {price}
                                                </div>
                                                {reducedPrice ? (
                                                    <div className="CatalogCard__reduced-price">{reducedPrice}</div>
                                                ) : null}
                                            </div>
                                        </a>

                                        <div>
                                            {item.StockStatus === StockStatus.InStock ? (
                                                <button
                                                    className="btn btn-outline"
                                                    onClick={onClickAddToCart(item.Code)}
                                                >
                                                    <Translation id="Form.Button.AddToCart.Label" />
                                                </button>
                                            ) : (
                                                <div className="msg-outOfStock">
                                                    <Translation id="Commerce.Product.Compare.ProductOutOfStock" />
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="ProductCompare__table__close">
                                        <a onClick={onClickRemove(item.Code)} href="#">
                                            <i className="fa fa-times"></i>
                                        </a>
                                    </div>
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">
                            <Translation id="Commerce.Product.Tabs.Sku.Label" />
                        </th>
                        {items.map((item) => (
                            <td key={item.Code}>{item.Code}</td>
                        ))}
                    </tr>
                    {specs.map((spec) => (
                        <tr key={spec.AttributeName}>
                            <th scope="row">{spec.AttributeDisplayName}</th>
                            {items.map((item) => (
                                <td key={item.Code}>{item.Attributes[spec.AttributeName]?.Values.join(', ')}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div>
                <button className="btn btn-danger" onClick={onClickClear}>
                    <Translation id="Commerce.Product.Compare.Clear" />
                </button>
            </div>
        </div>
    ) : (
        <div>
            <Translation id="Commerce.Product.Compare.NoItems" />
        </div>
    );
}

ProductCompare.propTypes = {
    items: px.arrayOf(
        px.shape({
            Code: px.string.isRequired,
            ListPrice: px.number,
            SalePrice: px.number,
            Title: px.string.isRequired,
            Url: px.string.isRequired,
            ImageUrl: px.string,
        })
    ),
    specs: px.arrayOf(px.shape({ AttributeName: px.string.isRequired, AttributeDisplayName: px.string.isRequired })),
    onRemoveFromCompareList: px.func.isRequired,
    onClearCompareList: px.func.isRequired,
    onAddItemToCart: px.func.isRequired,
    isLoading: px.bool,
};
